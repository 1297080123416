// src/Components/HomeComponent.js
import React from 'react';
import './HomeComponent.css';

function HomeComponent() {
  return (
    <div className="home-container flex flex-col items-center justify-center min-h-screen bg-gray-950 text-white">
      <h1 className="text-4xl font-bold mb-4 text-center">Welcome to Doomedx and all things Xcopy</h1>
      <h2 className="text-2xl italic text-gray-400">More Coming Soon</h2>
    </div>
  );
}

export default HomeComponent;
