// src/Components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar bg-gray-950 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo or Brand Name */}
        <div className="text-xl font-bold">
          Doomedx
        </div>
        {/* Hamburger Icon for Mobile */}
        <button onClick={toggleMenu} className="block md:hidden focus:outline-none">
          <span className="material-icons">menu</span>
        </button>
        {/* Navigation Links */}
        <div className={`flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 ${isOpen ? 'block' : 'hidden'} md:block`}>
          <Link to="/damage-control" className="hover:text-red-400 transition-colors duration-200">Damage Control</Link>
          <Link to="/exit-strategy" className="hover:text-red-400 transition-colors duration-200">Exit Strategy</Link>
          <Link to="/grifter" className="hover:text-red-400 transition-colors duration-200">Grifter</Link>
          <Link to="/known-origin" className="hover:text-red-400 transition-colors duration-200">Known Origin</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
