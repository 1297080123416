// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css'; // Tailwind CSS
import Navbar from './Components/Navbar';
import HomeComponent from './Components/HomeComponent';
import MemesSalesComponent from './Components/MemesSalesComponent';
import DamageControlComponent from './Components/DamageControlComponent';
import GrifterComponent from './Components/GrifterComponent';
import KnownOriginComponent from './Components/KnownOriginComponent';

function App() {
  return (
    <div className="bg-gray-950 min-h-screen text-white">
      <Router>
        <Navbar />
        <main className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/exit-strategy" element={<MemesSalesComponent />} />
            <Route path="/damage-control" element={<DamageControlComponent />} />
            <Route path="/grifter" element={<GrifterComponent />} />
            <Route path="/known-origin" element={<KnownOriginComponent />} />
            {/* Optional: Redirect unknown routes to Home or a 404 page */}
            <Route path="*" element={<HomeComponent />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default App;
