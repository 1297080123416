// src/Components/MemesSalesComponent.js

import React, { useEffect, useState, useRef } from 'react';
import { fetchData } from '../apiService';
import { CSVLink } from 'react-csv';
import { ArrowUpDown } from "lucide-react";
import ReactPaginate from 'react-paginate';
import etherscanIcon from '../assets/etherscan.png'; // Ensure the path is correct
import openseaIcon from '../assets/openseaIcon.png'; // Path to OpenSea icon
import seizeIcon from '../assets/seize.png'; // Path to Seize icon
import exitstrategyGif from '../assets/exitstrategy.gif'; // Path to the GIF

function MemesSalesComponent() {
  // State for memessales data
  const [salesData, setSalesData] = useState([]);
  const [loadingSales, setLoadingSales] = useState(true);
  const [errorSales, setErrorSales] = useState(null);

  // State for aggregated data
  const [aggregatedData, setAggregatedData] = useState({
    additionalAttributes: {},
    calculations: {},
  });
  const [loadingAggregated, setLoadingAggregated] = useState(true);
  const [errorAggregated, setErrorAggregated] = useState(null);

  // State for memesowners data with pagination
  const [ownersData, setOwnersData] = useState([]);
  const [ownersPagination, setOwnersPagination] = useState({
    page: 1,
    limit: 25,
    totalPages: 1,
    totalRecords: 0,
  });
  const [loadingOwners, setLoadingOwners] = useState(true);
  const [errorOwners, setErrorOwners] = useState(null);

  // Pagination States for memessales
  const [currentPageSales, setCurrentPageSales] = useState(1);
  const [totalPagesSales, setTotalPagesSales] = useState(1);
  const recordsPerPageSales = 25;

  // Pagination States for memesowners
  const [currentPageOwners, setCurrentPageOwners] = useState(1);
  const recordsPerPageOwners = 25;

  // Filter and Sorting States for memessales
  const [saleType, setSaleType] = useState('all'); // 'all', 'ask', 'bid'
  const [sortBy, setSortBy] = useState('createdat'); // 'createdat', 'decimal', 'usd'
  const [sortOrder, setSortOrder] = useState('desc'); // Default to 'desc' for newest first

  // State for all records (for CSV download)
  const [allSalesData, setAllSalesData] = useState([]);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const [csvError, setCsvError] = useState(null);

  // Ref for the hidden CSVLink
  const csvLinkRef = useRef();

  /**
   * Fetch memessales data based on currentPageSales, saleType, sortBy, and sortOrder
   */
  useEffect(() => {
    const getSalesData = async () => {
      setLoadingSales(true);
      setErrorSales(null);
      try {
        // Build query parameters
        const params = new URLSearchParams();
        params.append('page', currentPageSales);
        params.append('limit', recordsPerPageSales);
        if (saleType !== 'all') {
          params.append('orderside', saleType);
        }
        if (sortBy) {
          params.append('sort_by', sortBy);
          params.append('sort_order', sortOrder);
        }

        const data = await fetchData(`memessales?${params.toString()}`);
        setSalesData(data.data);
        setTotalPagesSales(data.totalPages); // Update totalPages based on API response
        setLoadingSales(false);
      } catch (err) {
        setErrorSales(err.message || 'Unexpected Error');
        setLoadingSales(false);
      }
    };

    getSalesData();
  }, [currentPageSales, saleType, sortBy, sortOrder]);

  /**
   * Fetch aggregated data from /api/memessalesaggregated
   */
  useEffect(() => {
    const getAggregatedData = async () => {
      setLoadingAggregated(true);
      setErrorAggregated(null);
      try {
        const data = await fetchData('memessalesaggregated');
        setAggregatedData({
          additionalAttributes: data.additionalAttributes || {},
          calculations: data.calculations || {},
        });
        setLoadingAggregated(false);
      } catch (err) {
        setErrorAggregated(err.message || 'Unexpected Error');
        setLoadingAggregated(false);
      }
    };

    getAggregatedData();
  }, []);

  /**
   * Fetch memesowners data with pagination
   */
  useEffect(() => {
    const getOwnersData = async () => {
      setLoadingOwners(true);
      setErrorOwners(null);
      try {
        const params = new URLSearchParams();
        params.append('page', currentPageOwners);
        params.append('limit', recordsPerPageOwners);

        const data = await fetchData(`memesowners?${params.toString()}`);
        setOwnersData(data.data);
        setOwnersPagination({
          page: data.page,
          limit: data.limit,
          totalPages: data.totalPages,
          totalRecords: data.totalRecords,
        });
        setLoadingOwners(false);
      } catch (err) {
        setErrorOwners(err.message || 'Unexpected Error');
        setLoadingOwners(false);
      }
    };

    getOwnersData();
  }, [currentPageOwners, recordsPerPageOwners]);

  // Function to format date and time
  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('en-US', options);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    });
    return (
      <div>
        <div>{formattedDate}</div>
        <div className="text-gray-400 text-sm">{formattedTime} UTC</div>
      </div>
    );
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (sortBy === field) {
      // Toggle sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
    setCurrentPageSales(1); // Reset to first page on sort change
  };

  // Function to fetch all records for CSV download
  const fetchAllRecords = async () => {
    setIsFetchingAll(true);
    setCsvError(null);
    try {
      // Fetch with a high limit to get all records (assuming totalRecords <= 500)
      const params = new URLSearchParams();
      params.append('page', 1);
      params.append('limit', 500); // Max limit is 500 as per backend
      if (saleType !== 'all') {
        params.append('orderside', saleType);
      }
      if (sortBy) {
        params.append('sort_by', sortBy);
        params.append('sort_order', sortOrder);
      }

      const data = await fetchData(`memessales?${params.toString()}`);
      setAllSalesData(data.data);
      setIsFetchingAll(false);

      // Trigger CSV download
      if (csvLinkRef.current) {
        csvLinkRef.current.link.click();
      }
    } catch (err) {
      setCsvError(err.message || 'Error fetching all records');
      setIsFetchingAll(false);
    }
  };

  // Function to prepare CSV data
  const prepareCSVData = () => {
    return allSalesData.map((sale) => ({
      Date: new Date(sale.createdat).toLocaleString(),
      Artwork: sale.image,
      Name: sale.name,
      'Sale Type': sale.orderside,
      'Sale Price (ETH)': parseFloat(sale.decimal).toFixed(2),
      'Sale Price (USD)': parseFloat(sale.usd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      Seller: `${sale.from_address.slice(0, 6)}...${sale.from_address.slice(-4)}`,
      Buyer: `${sale.to_address.slice(0, 6)}...${sale.to_address.slice(-4)}`,
      'Tx Hash': sale.txhash,
    }));
  };

  // Handle page change using ReactPaginate for memessales
  const handlePageClickSales = (selectedItem) => {
    setCurrentPageSales(selectedItem.selected + 1);
  };

  // Handle page change using ReactPaginate for memesowners
  const handlePageClickOwners = (selectedItem) => {
    setCurrentPageOwners(selectedItem.selected + 1);
  };

  // Render loading state for memessales, aggregated data, and memesowners
  if (loadingSales || loadingAggregated || loadingOwners) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-3xl font-bold mb-4">Sales</h2>
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 mb-4"></div>
        <p className="text-gray-400">Loading...</p>
      </div>
    );
  }

  // Render error state for memessales, aggregated data, or memesowners
  if (errorSales || errorAggregated || errorOwners) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-3xl font-bold mb-4">Sales</h2>
        <p className="text-red-400">
          Error: {errorSales || errorAggregated || errorOwners}
        </p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-950 p-8">
      <div className="mx-auto max-w-6xl space-y-8">
        {/* Header Section */}
        <div className="grid gap-8 lg:grid-cols-2">
          {/* Left: NFT Image */}
          <div className="flex aspect-square items-center justify-center rounded-lg border border-red-900/20 bg-black p-4">
            <a href={exitstrategyGif} target="_blank" rel="noopener noreferrer">
              <img
                src={exitstrategyGif}
                alt="Exit Strategy"
                className="w-full h-full object-contain rounded cursor-pointer"
              />
            </a>
          </div>

          {/* Right: NFT Details */}
          <div className="space-y-6 rounded-lg border border-red-900/20 bg-black p-6">
            <div>
              <h1 className="text-4xl font-bold text-white">Exit Strategy</h1>
              <p className="mt-2 text-xl text-red-400">6529 Memes Card 47</p>
            </div>

            <p className="text-gray-300">
              Exit Strategy was the final card in the opening season 1 of the 6529 Memes collection. 
              There are nearly 300 cards at the time of writing and Exit Strategy is still one of the 
              top 3 premium cards within the collection.
            </p>

            {/* Mint Information */}
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-400">Mint Date:</span>
                <span className="text-gray-300">16 December 2022</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Mint Price:</span>
                <span className="text-gray-300">0.06529 ETH</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Supply:</span>
                <span className="text-gray-300">420</span>
              </div>
            </div>

            <div className="flex gap-4">
              <a
                href="https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/47"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 rounded-lg border border-red-900/20 bg-gray-950 px-4 py-2 text-white transition-colors hover:bg-black"
              >
                <img src={openseaIcon} alt="OpenSea" className="w-5 h-5" />
                View on OpenSea
              </a>
              <a
                href="https://seize.io/the-memes/47?focus=live"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 rounded-lg border border-red-900/20 bg-gray-950 px-4 py-2 text-white transition-colors hover:bg-black"
              >
                <img src={seizeIcon} alt="Seize" className="w-5 h-5" />
                View on Seize
              </a>
            </div>
          </div>
        </div>

        {/* Summary Section */}
        <div className="grid gap-6 lg:grid-cols-3">
          {/* Owners Count */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Owners Count</h3>
            <p className="mt-2 text-2xl font-bold text-white">{ownersPagination.totalRecords || 'N/A'}</p>
          </div>

          {/* Market Cap */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Market Cap</h3>
            <p className="mt-2 text-2xl font-bold text-white">
              {aggregatedData.additionalAttributes.market_cap
                ? `$${parseFloat(aggregatedData.additionalAttributes.market_cap).toLocaleString()}`
                : 'N/A'}
            </p>
          </div>

          {/* All Time Revenue */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">All Time Revenue</h3>
            <p className="mt-2 text-2xl font-bold text-white">
              {aggregatedData.additionalAttributes.total_volume
                ? `$${parseFloat(aggregatedData.additionalAttributes.total_volume).toLocaleString()}`
                : 'N/A'}
            </p>
          </div>

          {/* Floor Price */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Floor Price</h3>
            <p className="mt-2 text-2xl font-bold text-white">
              {aggregatedData.additionalAttributes.floor_price
                ? `${parseFloat(aggregatedData.additionalAttributes.floor_price).toFixed(2)} ETH`
                : 'N/A'}
            </p>
          </div>

          {/* Top Bid */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Top Bid</h3>
            <p className="mt-2 text-2xl font-bold text-white">
              {aggregatedData.additionalAttributes.highest_offer
                ? `${parseFloat(aggregatedData.additionalAttributes.highest_offer).toFixed(2)} ETH`
                : 'N/A'}
            </p>
          </div>

          {/* Last Sale */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Last Sale</h3>
            <p className="mt-2 text-xl font-bold text-white">
              {aggregatedData.calculations.last_sale_price && aggregatedData.calculations.last_sale_date
                ? `${aggregatedData.calculations.last_sale_price.toFixed(2)} ETH on ${new Date(aggregatedData.calculations.last_sale_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}`
                : 'N/A'}
            </p>
          </div>

          {/* Highest Sale Price */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Highest Sale Price</h3>
            <p className="mt-2 text-xl font-bold text-white">
              {aggregatedData.calculations.highest_sale_price && aggregatedData.calculations.highest_sale_price_date
                ? `${aggregatedData.calculations.highest_sale_price.toFixed(2)} ETH on ${new Date(aggregatedData.calculations.highest_sale_price_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}`
                : 'N/A'}
            </p>
          </div>

          {/* Average Sale Price */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Average Sale Price</h3>
            <p className="mt-2 text-2xl font-bold text-white">
              {aggregatedData.calculations.average_sale_price
                ? `${aggregatedData.calculations.average_sale_price.toFixed(2)} ETH`
                : 'N/A'}
            </p>
          </div>

          {/* Last 7 Days Revenue */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <h3 className="text-lg font-semibold text-red-400">Last 7 Days Revenue</h3>
            <p className="mt-2 text-2xl font-bold text-white">
              {aggregatedData.additionalAttributes.total_volume_last_7_days
                ? `$${parseFloat(aggregatedData.additionalAttributes.total_volume_last_7_days).toLocaleString()}`
                : 'N/A'}
            </p>
          </div>
        </div>

        {/* Sales Table Section */}
        <div className="rounded-lg border border-red-900/20 bg-black p-6">
          <div className="mb-6 flex items-center justify-between">
            <h2 className="text-2xl font-bold text-white">Sales History</h2>
            <p className="text-sm text-gray-400">Data updates every 12 hours</p>
          </div>

          {/* Controls: Filter, Download */}
          <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-2 md:space-y-0">
            {/* Filter for Sale Type */}
            <div>
              <label htmlFor="saleType" className="mr-2 text-gray-400">Filter by Sale Type:</label>
              <select
                id="saleType"
                value={saleType}
                onChange={(e) => {
                  setSaleType(e.target.value);
                  setCurrentPageSales(1); // Reset to first page on filter change
                }}
                className="bg-gray-800 text-gray-300 border border-red-900/20 rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                <option value="all">All</option>
                <option value="ask">Ask</option>
                <option value="bid">Bid</option>
              </select>
            </div>

            {/* Download to CSV */}
            <div>
              <button
                onClick={fetchAllRecords}
                disabled={isFetchingAll}
                className={`bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded ${isFetchingAll ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isFetchingAll ? 'Preparing CSV...' : 'Download All to CSV'}
              </button>
              {csvError && <p className="text-red-400 mt-2">Error: {csvError}</p>}
              {/* Hidden CSVLink */}
              <CSVLink
                data={prepareCSVData()}
                filename="memes_sales_data.csv"
                className="hidden"
                ref={csvLinkRef}
              />
            </div>
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-black border border-red-900/20">
              <thead>
                <tr>
                  <th
                    className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('createdat')}
                  >
                    <button className="flex items-center gap-1">
                      Date
                      <ArrowUpDown className="h-4 w-4" />
                      {sortBy === 'createdat' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </button>
                  </th>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Artwork</th>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Sale Type</th>
                  <th
                    className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('decimal')}
                  >
                    <button className="flex items-center gap-1">
                      Sale Price (ETH)
                      <ArrowUpDown className="h-4 w-4" />
                      {sortBy === 'decimal' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </button>
                  </th>
                  <th
                    className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('usd')}
                  >
                    <button className="flex items-center gap-1">
                      Sale Price (USD)
                      <ArrowUpDown className="h-4 w-4" />
                      {sortBy === 'usd' && (sortOrder === 'asc' ? '↑' : '↓')}
                    </button>
                  </th>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Seller</th>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Buyer</th>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Tx Hash</th>
                </tr>
              </thead>
              <tbody>
                {salesData.map((sale) => (
                  <tr key={sale.txhash} className="hover:bg-gray-950">
                    <td className="px-6 py-4 border-b border-red-900/10">
                      {formatDate(sale.createdat)}
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      <img src={sale.image} alt={sale.name} className="w-16 h-16 object-cover rounded" loading="lazy" />
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      {sale.name}
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10 capitalize">
                      {sale.orderside}
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      {parseFloat(sale.decimal).toFixed(2)} ETH
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      ${parseFloat(sale.usd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      {sale.from_address.slice(0, 6)}...{sale.from_address.slice(-4)}
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      {sale.to_address.slice(0, 6)}...{sale.to_address.slice(-4)}
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      <a href={`https://etherscan.io/tx/${sale.txhash}`} target="_blank" rel="noopener noreferrer">
                        <img src={etherscanIcon} alt="Etherscan" className="w-6 h-6" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls with ReactPaginate for memessales */}
          <div className="flex justify-center items-center mt-4">
            <ReactPaginate
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              breakLabel={'...'}
              pageCount={totalPagesSales}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClickSales}
              containerClassName={'flex space-x-2'}
              pageClassName={'px-3 py-1 border border-red-500 rounded hover:bg-red-500 hover:text-white'}
              previousClassName={'px-3 py-1 border border-red-500 rounded hover:bg-red-500 hover:text-white'}
              nextClassName={'px-3 py-1 border border-red-500 rounded hover:bg-red-500 hover:text-white'}
              breakClassName={'px-3 py-1 border border-red-500 rounded'}
              activeClassName={'bg-red-500 text-white'}
            />
          </div>
        </div>

        {/* Owners Table Section */}
        <div className="rounded-lg border border-red-900/20 bg-black p-6">
          <div className="mb-6 flex items-center justify-between">
            <h2 className="text-2xl font-bold text-white">Owners</h2>
            <p className="text-sm text-gray-400">Data updates every 12 hours</p>
          </div>

          {/* Owners Count */}
          <div className="mb-4">
            <span className="text-gray-400">Owners Count:</span>
            <span className="ml-2 text-white font-semibold">{ownersPagination.totalRecords || 'N/A'}</span>
          </div>

          {/* Owners Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-black border border-red-900/20">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Address</th>
                  <th className="px-6 py-3 border-b border-red-900/10 text-left text-red-500 uppercase tracking-wider">Token Count</th>
                </tr>
              </thead>
              <tbody>
                {ownersData.map((owner) => (
                  <tr key={owner.address} className="hover:bg-gray-950">
                    <td className="px-6 py-4 border-b border-red-900/10">
                      {owner.address}
                    </td>
                    <td className="px-6 py-4 border-b border-red-900/10">
                      {owner.token_count}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls with ReactPaginate for memesowners */}
          <div className="flex justify-center items-center mt-4">
            <ReactPaginate
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              breakLabel={'...'}
              pageCount={ownersPagination.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClickOwners}
              containerClassName={'flex space-x-2'}
              pageClassName={'px-3 py-1 border border-red-500 rounded hover:bg-red-500 hover:text-white'}
              previousClassName={'px-3 py-1 border border-red-500 rounded hover:bg-red-500 hover:text-white'}
              nextClassName={'px-3 py-1 border border-red-500 rounded hover:bg-red-500 hover:text-white'}
              breakClassName={'px-3 py-1 border border-red-500 rounded'}
              activeClassName={'bg-red-500 text-white'}
              forcePage={currentPageOwners - 1} // Sync ReactPaginate with currentPageOwners
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemesSalesComponent;