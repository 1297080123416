// src/Components/KnownOriginComponent.js
import React from 'react';
import './KnownOriginComponent.css';

function KnownOriginComponent() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-950">
      <h1 className="text-4xl font-bold text-gray-300">*** Coming Soon ***</h1>
    </div>
  );
}

export default KnownOriginComponent;
